import { PhoneIcon, EnvelopeIcon, MapPinIcon } from '@heroicons/react/24/solid'

const emails = [
  { name: 'Entrevistas', mail: 'interviews@x100fm.com'},
  { name: 'Programas', mail: 'shows@x100fm.com'},
  { name: 'Comercialización', mail: 'marketing@x100fm.com'},
]

const contacts = [
  { name: '+57 (320) 8510712', icon: PhoneIcon, url: 'tel:+573208510712'},
  { name: '+57 (323) 8131216', icon: PhoneIcon, url: 'tel:+573238131216'},
  { name: 'm@x100fm.com', icon: EnvelopeIcon, url: 'mail:m@x100fm.com'},
  { name: 'Medellín, Colombia', icon: MapPinIcon, url: ''},
]

export default function Footer() {
  return (
    <div className="bg-gradient-to-b from-deep-purple-500 to-indigo-900">
      <div className="grid grid-cols-1 gap-0 lg:grid-cols-4 lg:gap-10">
        <div className="md:col-span-2 py-10 mx-10 md:ml-10">
          <h1 className="text-4xl font-bold text-white">X100 FM</h1>
          <div className="mb-5 mt-2 h-[1px] w-[80%] bg-violet-500"></div>
          <p className="text-white mb-5">Somos la primera radio visual de Venezuela y pioneros de la radio visual desde 2014.</p>
          <p className="text-white">Dentro de nuestra grilla tenemos más de 30 programas de producción original con temas de: Actualidad, información, motivación, economía y finanzas, educación, gastronomía, deportes, fitness, cine y teatro, música, tecnología, salud, ecología, viajes y turismo, emprendimiento, negocios, moda, mercadeo y ventas, arte, espectáculo, etiqueta, protocolo, estilos de vida, bienes raíces y mucho más.</p>
        </div>
        <div className="py-10 mx-10 md:ml-10">
          {emails.map((it, i) => (
            <div className={emails.length !== i - 1 ? "mb-5" : ''}>
              <h2 className="text-4xl font-bold text-white">{it.name}</h2>
              <div className="mb-1 mt-1 h-[1px] w-[80%] bg-violet-500"></div>
              <p className="text-white">{it.mail}</p>
            </div>
          ))}
        </div>
        <div className="bg-indigo-900 py-10 px-10">
          <div>
            <h2 className="text-4xl font-bold text-white">Contacto</h2>
            <div className="mb-5 mt-2 h-[1px] w-[80%] bg-indigo-700"></div>
            {contacts.map((it, i) => (
              <a href={it.url} className="text-white flex flex-row items-center mb-2">
                <it.icon className="h-5 w-5 mr-2 text-white" aria-hidden="true" /> {it.name}
              </a>
            ))}
          </div>
          <div className="mt-10">
            <p className="text-white font-light">
              <span className="font-bold">X100 FM &copy; {new Date().getFullYear()}</span><br/>
              Todos los derechos reservados
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
