import {useEffect, useState} from "react";
import {UserIcon} from "@heroicons/react/20/solid";
import moment from "moment-timezone";
import "moment/locale/es-do";

export default function Interactions() {
  const [messages, setMessages] = useState([]);

  const getMessages = async () => {
    const fetchData = await fetch('https://cors-jrc.vercel.app/chat/messages', {
      method: 'GET',
      redirect: 'follow'
    });

    const fetchJson = await fetchData.json();
    if (fetchJson.messages.length) {
      setMessages(fetchJson.messages);
    }
  };

  useEffect(() => {
    getMessages().catch(console.log);
    const clock = setInterval(() => {
      getMessages().catch(console.log);
    }, 60 * 60 * 3);
    return () => clearInterval(clock);
  }, []);

  if (!messages.length) {
    return null;
  }

  return (
    <div className="fixed w-full lg:w-1/2 bottom-0 lg:bottom-4 lg:left-4">
      <span className="py-2 px-6 flex flex-row text-sm md:text-md capitalize bg-white lg:rounded-tl-lg lg:rounded-tr-lg">
        <UserIcon className="h-4 w-4 mr-1 md:h-5 md:w-5" aria-hidden="true" />{' '}
        <span className="mr-2 truncate"><span className="font-bold">{messages[0].name}</span> - {moment(messages[0].timestamp).tz('America/Bogota').fromNow()} ({moment(messages[0].timestamp).tz('America/Bogota').format('lll')})</span>
      </span>
      <div className="pt-2 pb-4 px-6 backdrop-filter backdrop-blur-lg bg-opacity-30 bg-indigo-700 lg:rounded-bl-lg lg:rounded-br-lg">
        <span className="text-white md:font-bold">{messages[0].message}</span>
      </div>
    </div>
  )
}
