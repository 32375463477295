import { useState, useEffect } from "react";

function useAudioPlayer() {
  const [metadata, setMetadata] = useState({
    playing: "X100 FM - En Vivo",
    id: 0,
    readable: true,
    title: "",
    title_short: "",
    title_version: "",
    link: "#",
    duration: 0,
    rank: 0,
    explicit_lyrics: false,
    explicit_content_lyrics: 0,
    explicit_content_cover: 0,
    preview: "",
    md5_image: "",
    artist: {
      id: 0,
      name: "",
      link: "",
      picture: "https://graph.facebook.com/x100fm/picture?type=large",
      picture_small: "https://graph.facebook.com/x100fm/picture?type=large",
      picture_medium: "",
      picture_big: "",
      picture_xl: "",
      tracklist: "",
      type: "artist"
    },
    album: {
      id: 0,
      title: "",
      cover: "",
      cover_small: "",
      cover_medium: "https://graph.facebook.com/x100fm/picture?type=large",
      cover_big: "",
      cover_xl: "https://graph.facebook.com/x100fm/picture?type=large",
      md5_image: "",
      tracklist: "",
      type: "album"
    },
    timestamp: 0
  });

  const getMetadata = () => {
    fetch("https://cors-jrc.vercel.app/4fsmn8h576duv", {
      method: 'GET',
      redirect: 'follow'
    })
      .then(r => r.json())
      .then(r => setMetadata(r))
      .catch(e => {
        console.log('ERROR/getMetadata', e)
      });
  }

  useEffect(() => {
    const clock = setInterval(() => {
      getMetadata();
    }, 3 * 1000);
    return () => clearInterval(clock);
  });

  return {
    metadata
  }
}

export default useAudioPlayer;
