import React, {useState} from "react";
import {mdiHandBackLeft} from "@mdi/js";
import Icon from "@mdi/react";

export default function SongRequest() {
  const [isWarning, setIsWarning] = useState('');
  const [isError, setIsError] = useState('');
  const [isSend, setIsSend] = useState('');
  const [form, setForm] = useState({
    name: '',
    url: '',
    message: '',
    terms: false,
  });

  const sendMessage = async () => {
    const {name, url, message} = form;

    setIsWarning('');
    setIsError('');
    setIsSend('');

    if (!name || !message) {
      return setIsError('Necesita colocar su nombre y un mensaje para poder enviar esta interacción.');
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const fetchData = await fetch("https://cors-jrc.vercel.app/chat/message", {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        name,
        url,
        message
      }),
      redirect: 'follow'
    });

    if (fetchData.status === 201) {
      setIsSend('¡Su interacción fue enviada con éxito!');
      handleCleanForm();
      return await fetchData.json();
    }

    return null;
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const handleTerms = () => {
    setForm({
      ...form,
      terms: !form.terms,
    })
  }

  const handleCleanForm = () => {
    setForm({
      ...form,
      url: '',
      message: '',
    })
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!form.terms) {
      return setIsWarning(`${form.name || 'Hola'}, debes aceptar los términos y condiciones para enviar tu interacción.`)
    }

    sendMessage().catch(console.log);
  }

  return (
    <div className="w-full p-5">
      <h2 className="text-base lg:text-xl font-bold text-[#333333] flex items-center">
        <Icon className="mr-1" path={mdiHandBackLeft} size={1} color="#333333" />
        Solicita tu tema favorito
      </h2>
      <div className="mb-5 mt-2 h-[1px] w-[80%] bg-gray-200"></div>
      <form onSubmit={handleOnSubmit}>
        <input className="mb-2 block w-full flex-1 rounded-xl border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" value={form.name} onChange={handleChange} placeholder="Tu nombre *" type="text" name="name" required />
        <input className="mb-2 block w-full flex-1 rounded-xl border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" value={form.url} onChange={handleChange} placeholder="URL del contenido que quieres ver" type="url" name="url" />
        <textarea rows={3} maxLength={140} className="block w-full flex-1 rounded-xl border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" value={form.message} onChange={handleChange} placeholder="Mensaje *" name="message" required />
        <span className="ml-2 text-xs text-gray-600">{`${form.message.length}/140 caracteres máximos`}</span>
        <div className="mt-4 mb-2 flex justify-center items-center">
          <input
            type="checkbox"
            checked={form.terms}
            onChange={handleTerms}
            className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          />
          <p className="text-gray-500 text-sm">Acepto los términos y políticas de privacidad para el uso de esta plataforma.</p>
        </div>
        <input
          className="text-[#333333] uppercase text-xl font-bold w-full py-2
            button bg-yellow-500 cursor-pointer select-none border-[0px] rounded-xl transition-all duration-150 [box-shadow:0_0_0_0_#facc15,3px_3px_0_0_#facc15]
            active:translate-y-[5px] active:translate-x-[5px] active:[box-shadow:0_0px_0_0_#fde047,0_0px_0_0_#fde047]"
          value="Enviar"
          type="submit"
        />
      </form>
      {isError ? (
        <div className="mt-4 border-2 border-red-500 bg-red-100 rounded-xl px-4 py-2">
          <span>{isError}</span>
        </div>
      ) : null}
      {isSend ? (
        <div className="mt-4 border-2 border-green-500 bg-green-100 rounded-xl px-4 py-2">
          <span>{isSend}</span>
        </div>
      ) : null}
      {isWarning ? (
        <div className="mt-4 border-2 border-orange-500 bg-orange-100 rounded-xl px-4 py-2">
          <span>{isWarning}</span>
        </div>
      ) : null}
    </div>
  )
}
