import React from "react";
import Icon from '@mdi/react';

export default function ActionPlayer(props) {
  const { handleClick, icon } = props;

  return (
    <button className="opacity-70 bg-black rounded-full" onClick={() => handleClick()}>
      <Icon path={icon} color="white" size={2} />
    </button>
  );
}
