import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const links = [
  { name: 'En vivo', href: '#', active: true},
  { name: 'Programación', href: '#' },
  { name: 'Contenidos', href: '#' },
]

export default function Header() {
  return (
    <Popover className="sticky top-0 backdrop-filter backdrop-blur-lg bg-opacity-80 bg-deep-purple-800" style={{zIndex: 1}}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="#" className="text-3xl font-bold uppercase">
              <span className="pl-4 px-2 py-1 text-white bg-[#333333] rounded-l-xl [box-shadow:0_0_0_0_#555555,3px_3px_0_0_#555555]">X100</span>
              <span className="pl-2 pr-4 py-1 text-[#333333] bg-yellow-500 rounded-r-xl [box-shadow:0_0_0_0_#facc15,3px_3px_0_0_#facc15]">FM</span>
            </a>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            {links.map((it) => (
              it.active ? <a href={it.href} className="whitespace-nowrap mx-3 uppercase text-xl font-bold px-4 py-2 text-white
              button bg-red-500 cursor-pointer select-none border-[0px] rounded-xl transition-all duration-150 [box-shadow:0_0_0_0_#f87171,3px_3px_0_0_#f87171]
              active:translate-y-[5px] active:translate-x-[5px] active:[box-shadow:0_0px_0_0_#fde047,0_0px_0_0_#fde047]
              ">
                {it.name}
              </a> : <a href={it.href} className="whitespace-nowrap mx-3 uppercase text-xl font-bold px-4 py-2 text-[#333333]
              button bg-yellow-500 cursor-pointer select-none border-[0px] rounded-xl transition-all duration-150 [box-shadow:0_0_0_0_#facc15,3px_3px_0_0_#facc15]
              active:translate-y-[5px] active:translate-x-[5px] active:[box-shadow:0_0px_0_0_#fde047,0_0px_0_0_#fde047]
              ">
                {it.name}
              </a>
            ))}
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus style={{zIndex: 2}} className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <a href="#" className="text-3xl font-bold uppercase">
                    <span className="pl-4 px-2 py-1 text-white bg-[#333333] rounded-l-xl [box-shadow:0_0_0_0_#555555,3px_3px_0_0_#555555]">X100</span>
                    <span className="pl-2 pr-4 py-1 text-[#333333] bg-yellow-500 rounded-r-xl [box-shadow:0_0_0_0_#facc15,3px_3px_0_0_#facc15]">FM</span>
                  </a>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-2 text-center">
                  {links.map((it) => (
                    it.active ? <a href={it.href} className="whitespace-nowrap uppercase text-xl font-bold px-4 py-2
              button bg-red-500 cursor-pointer select-none border-[0px] rounded-xl transition-all duration-150 [box-shadow:0_0_0_0_#f87171,3px_3px_0_0_#f87171]
              active:translate-y-[5px] active:translate-x-[5px] active:[box-shadow:0_0px_0_0_#fde047,0_0px_0_0_#fde047]
              ">
                      <span className="text-white">{it.name}</span>
                    </a> : <a href={it.href} className="whitespace-nowrap uppercase text-xl font-bold px-4 py-2
              button bg-yellow-500 cursor-pointer select-none border-[0px] rounded-xl transition-all duration-150 [box-shadow:0_0_0_0_#facc15,3px_3px_0_0_#facc15]
              active:translate-y-[5px] active:translate-x-[5px] active:[box-shadow:0_0px_0_0_#fde047,0_0px_0_0_#fde047]
              ">
                      <span className="text-[#333333]">{it.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
