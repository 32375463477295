import Icon from '@mdi/react';
import {mdiTwitter, mdiFacebook, mdiInstagram, mdiYoutube} from '@mdi/js';

const links = [
  {icon: mdiTwitter, url: 'https://twitter.com/X100FM', style: 'bg-[#00acee]'},
  {icon: mdiFacebook, url: 'https://www.facebook.com/X100FM', style: 'bg-[#4267B2]'},
  {icon: mdiInstagram, url: 'https://wwww.instagram.com/X100FM', style: 'bg-gradient-to-r from-yellow-300 via-pink-600 to-blue-600'},
  {icon: mdiYoutube, url: 'https://www.youtube.com/channel/UCKCR0BGirF_KpwOdEdD_4-w', style: 'bg-[#FF0000]'},
];

export default function SocialLine() {
  return (
    <div className="grid grid-cols-4">
      {links.map((it) => (
        <a href={it.url}>
          <div className={`h-20 flex justify-center items-center text-center ${it.style}`}>
            <Icon path={it.icon} className="w-12 h-12 text-white" />
          </div>
        </a>
      ))}
    </div>
  )
}
