import React, {useRef, useState} from "react";
import { mdiPlayCircle, mdiPauseCircle, mdiHandBackLeft, mdiShareCircle } from '@mdi/js';
import currency from "currency.js";
import Icon from "@mdi/react";

import useAudioPlayer from "./useAudioPlayer";
import ActionPlayer from "./ActionPlayer";

export default function Player() {
  const audio = useRef();
  const {metadata} = useAudioPlayer();
  const [playing, setPlaying] = useState(false);

  const play = () => {
    setPlaying(true);
    audio.current.play()
  }

  const pause = () => {
    setPlaying(false);
    audio.current.pause()
  }

  return (
    <div className="w-full h-full bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url('${metadata.album.cover_xl || 'https://graph.facebook.com/x100fm/picture?type=large'}')`}}>
      <div className="w-full h-full backdrop-filter backdrop-blur-lg backdrop-brightness-50 flex flex-col justify-center items-center">
        <audio ref={audio}>
          <source src="https://stream.zeno.fm/vdesnc2fzmzuv" />
          Your browser does not support the <code>audio</code> element.
        </audio>
        <div className="flex justify-center items-center">
          <div className="absolute">
            {playing ?
              <ActionPlayer icon={mdiPauseCircle} handleClick={pause} /> :
              <ActionPlayer icon={mdiPlayCircle} handleClick={play} />
            }
          </div>
          <img className="rounded-xl shadow-xl w-28 h-28 sm:w-40 sm:h-40 lg:w-52 lg:h-52" src={metadata.album.cover_medium || 'https://graph.facebook.com/x100fm/picture?type=large'} alt={`${metadata.title}`} />
        </div>
        <div className="absolute bottom-2 left-2">
          <div className="text-sm md:text-base w-fit text-[#333333] bg-yellow-500 py-1 px-2 uppercase font-bold rounded-t-xl">
            Sonando ahora
          </div>
          <div className="flex items-center bg-[#333333] rounded-xl">
            <img className="w-12 h-12 rounded-bl-xl rounded-tr-xl shadow-xl" src={metadata.artist.picture} alt={metadata.artist.name} />
            <div className="text-white text-lg md:text-2xl font-bold mb-1 px-3 truncate max-w-[18rem] lg:max-w-lg">
              {metadata.playing}
            </div>
          </div>
        </div>
        <div className="absolute top-2 left-2 flex flex-row items-center">
          <a href={metadata.link} target="_blank">
            <Icon className="mr-1" path={mdiShareCircle} size={1.2} color="white" />
          </a>
          <div className="text-white flex flex-row items-center">
            <Icon className="mr-1" path={mdiHandBackLeft} size={1} color="white" />
            {currency(metadata.rank, { separator: '.', pattern: `#`, precision: 0 }).format()}
          </div>
        </div>
        {metadata.explicit_lyrics ? (
          <div className="absolute top-2 right-2 flex flex-row items-center">
            <div className="text-xs md:text-base w-fit text-[#333333] bg-white py-1 px-2 uppercase font-bold">
              Explícito
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
