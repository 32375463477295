import Iframe from 'react-iframe'

import Interactions from "./components/Interactions";
import ClockOnLine from "./components/ClockOnLine";
import SongRequest from "./components/SongRequest";
import SocialLine from "./components/SocialLine";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Player from "./components/Player";

function App() {
  return (
    <div>
      <Header />
      <div className="grid gap-y-5 grid-cols-1 px-5 xs:p-0 lg:grid-cols-3 lg:gap-5 container mx-auto my-5 xs:my-10">
        <div className="col-span-2">
          <div className="aspect-w-16 aspect-h-9">
            <Player />
          </div>
        </div>
        <div>
          <ClockOnLine />
          <SongRequest />
        </div>
      </div>
      <div className="container mx-auto my-5">
        <Iframe
          url="https://tunein.com/embed/player/s278913?scheme=f87171"
          className="w-full h-[100px] rounded-lg border-2 border-[#333333]"
          frameBorder="no"
          scrolling="no"
        />
      </div>
      <SocialLine />
      <Footer />
      <Interactions />
    </div>
  );
}

export default App;
