import {useEffect, useRef} from "react";
import { ClockIcon } from '@heroicons/react/24/solid'
import moment from "moment-timezone";
import "moment/locale/es-do";

export default function ClockOnLine() {
  const span = useRef();
  const counter = () => {
    const now = moment.tz('America/Bogota');
    return now.format('LTS');
  };

  useEffect(() => {
    const clock = setInterval(() => {
      span.current.innerHTML = `${counter()}`;
    }, 1000);
    return () => clearInterval(clock);
  }, []);

  return (
    <div className="rounded-xl w-full py-3 bg-red-500 flex flex-col justify-center items-center">
      <span className="flex flex-row items-center justify-center text-sm md:text-md uppercase text-white">
        <ClockIcon className="h-4 w-4 mr-1 md:h-5 md:w-5" aria-hidden="true" /> en el estudio
      </span>
      <span ref={span} className="text-white font-bold text-3xl md:text-4xl">{counter()}</span>
      <p className="text-white text-base md:text-lg">{moment.tz('America/Bogota').format('LL')}</p>
    </div>
  )
}
